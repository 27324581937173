<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Processamento de Campanhas</h1>
      <div class="container-btns" v-if="perfil != 5 && perfil != null">
        <button class="btn btn-laranja" @click="processarCampanhas">
          Processar Campanhas
        </button>
      </div>
      <form @submit.prevent class="formulario-container">
        <div class="d-md-flex">
          <div class="formulario-item">
            <label class="formulario-item-label">CPF</label>
            <input type="text" v-model="form.cpf" v-mask="'###.###.###-##'" placeholder="CPF" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Nome da Campanha</label>
            <multiselect v-model="form.nomeCampanha" label="titulo" track-by="titulo" :options="nomeCampanha"
              :searchable="false" :multiple="false" placeholder="Nome do Treinamento" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Data</label>
            <datepicker v-model="form.dataConclusao" :language="ptBR" />
          </div>
        </div>
        <div class="d-md-flex">
          <div class="formulario-item medio">
            <label class="formulario-item-label">Mês Vigente</label>
            <multiselect v-model="form.mes" label="nome" track-by="nome" :options="opcoesMeses" :searchable="false"
              :multiple="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" />
          </div>

          <div class="formulario-item medio">
            <label class="formulario-item-label">Ano Vigente</label>
            <input type="text" v-model="form.ano" />
          </div>
        </div>
        <div class="container-btns">
          <button class="btn btn-cancelar" @click="limparDados">
            Limpar
          </button>
          <button class="btn btn-laranja" @click="listar(1, true)">
            Filtrar
          </button>
        </div>
      </form>

      <transition name="fade-left" mode="out-in">
        <div class="paginacao" v-if="!semDados">
          <button :disabled="paginaBusca == 1" @click="pagAnterior">
            Anterior
          </button>
          <p>{{ paginaBusca }}</p>
          <button :disabled="ultimoItem" @click="proxPagina">
            Próxima
          </button>
        </div>
      </transition>

      <div v-if="campanhas.length > 0" class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-cpf">
            <p>DATA APURAÇÃO</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>CPF</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>TIPO DE CAMPANHA</p>
          </div>
          <div class="tabela-linha-nome">
            <p>NOME DA CAMPANHA</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>PONTUAÇÃO</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>COLOCAÇÃO</p>
          </div>
          <div class="tabela-linha-nome">
            <p>PRÊMIO</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>MÊS VIGENTE</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>ANO VIGENTE</p>
          </div>
        </div>
        <div v-for="(campanha, index) in campanhas" :key="index" class="tabela-linha tabela-linha-informacoes">
          <div class="tabela-linha-cpf">
            <p>{{ formatarData(campanha.dataApuracao) }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ campanha.cpfParticipante }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ campanha.tipoCampanha }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ campanha.nomeCampanha }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ campanha.pontuacao }} PONTOS</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ campanha.posicao }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ campanha.campanhaPremio }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ campanha.mesVigente }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ campanha.anoVigente }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import {
  comboCampanhas,
  listarCampanhasApuracao,
} from "@/services/apuracao-campanhas";
import moment from "moment";
import { opcoesMeses } from '@/utils/json'

export default {
  name: "campanhasListar",
  components: {
    Datepicker,
    Multiselect,
  },
  data() {
    return {
      opcoesMeses: opcoesMeses.mes,
      semDados: true,
      paginaBusca: 1,
      ptBR: ptBR,
      nomeCampanha: [],
      campanhas: [],
      form: {
        nomeCampanha: {},
        mes: "",
        cpf: "",
        data: "",
        ano: "",
      },
      perfil: null
    };
  },
  created() {
    this.listar(1, false);
    this.selectCampanhas();
    this.perfil = localStorage.getItem('perfil');
  },
  methods: {
    selectCampanhas() {
      comboCampanhas().then((resp) => {
        this.nomeCampanha = resp.data;
      });
    },
    processarCampanhas() {
      this.$router.push("/campanhas/adicionar");
    },
    limparDados() {
      this.form = {
        nomeCampanha: {},
        mes: "",
        cpf: "",
        data: "",
        ano: "",
      };

      this.listar(1, false);
    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    listar(pag, tipoFiltro) {
      let data = {};
      if (tipoFiltro) {
        data = {
          campanha: this.form.nomeCampanha.id,
          nomeUsuario: "",
          cpf: this.form.cpf.replaceAll(".", "").replace("-", ""),
          dataConclusao: this.form.dataConclusao
            ? moment(this.form.dataConclusao).format("YYYY-MM-DD")
            : "",
          dataInicio: "",
          dataFim: "",
          mesVigente: this.form.mes && this.form.mes.id ? this.form.mes.id : 0,
          anoVigente: this.form.ano ? Number(this.form.ano) : 0,
          idNome: this.form.nome && this.form.nome.id ? this.form.nome.id : 0,
          pagina: this.paginaBusca,
          qtdPorPagina: 15,
        };
      } else {
        data = {
          campanha: 0,
          nomeUsuario: "",
          cpf: "",
          dataConclusao: "",
          dataInicio: "",
          dataFim: "",
          mesVigente: 0,
          anoVigente: 0,
          idTipo: 0,
          idNome: 0,
          pagina: this.paginaBusca,
          qtdPorPagina: 15,
        };
      }

      listarCampanhasApuracao(data).then((resp) => {
        if (resp.data.lista.length == 0) {
          this.campanhas = [];
          if (pag && pag > 1) {
            this.ultimoItem = true;
          } else {
            this.semDados = true;
          }
        } else {
          resp.data.lista.length < 15
            ? (this.ultimoItem = true)
            : (this.ultimoItem = false);
          if (pag) {
            this.paginaBusca = pag;
          }
          this.semDados = false;
          this.campanhas = resp.data.lista;
        }
      });
    },
    pagAnterior() {
      const pag = --this.paginaBusca;
      this.listar(pag);
    },
    proxPagina() {
      const pag = ++this.paginaBusca;
      this.listar(pag);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss" scoped>
.medio {
  @media (min-width:768px) {
    width: 33.33% !important;
  }
}
</style>